import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h2>Gunstock Oak</h2>
      <h3><small>By</small> <i>The Bergamo Collection</i></h3>

      <hr/>
      <br/>
      <div id={"stats"}>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>Type</div>
          <div className={"stats-val"}>Engineered</div>
        </div>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>Material</div>
          <div className={"stats-val"}>Hardwood</div>
        </div>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>Length</div>
          <div className={"stats-val"}>50–75"</div>
        </div>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>Width</div>
          <div className={"stats-val"}>5-1/2"</div>
        </div>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>Height</div>
          <div className={"stats-val"}>25mm</div>
        </div>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>Veneer</div>
          <div className={"stats-val"}>–</div>
        </div>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>RIC Rating</div>
          <div className={"stats-val"}>4.7 out of 5</div>
        </div>
        <div className={"stats-row"}>
          <div className={"stats-prop"}>SKU</div>
          <div className={"stats-val"}>R0013</div>
        </div>
      </div>
    </div>
  );
}

export default App;
